import React from 'react';
// import Accordion from './Components/Accordion/Accordion';
import Candlestick from './Components/highcharts/Candlestick/Candlestick';
// import CandlestickChart from './Components/highcharts/Candlestick/CandlestickChart';

const App = () => {
  return (
    <>
      {/* <Accordion /> */}
      <Candlestick />
      {/* <CandlestickChart /> */}
    </>
  );
};

export default App;